<template>
  <!-- Header -->
  <header>
    <picture>
      <source srcset="@/assets/images/logo.webp" type="image/webp" />
      <img src="@/assets/images/logo.png" alt="logo" />
    </picture>
  </header>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderHome",
  data() {
    return {};
  },
  props: {},
  methods: {},
});
</script>

<style lang="scss" scoped>
header {
  background-color: rgba(255, 252, 55, 0.25);
}
</style>
