import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeWrapper from "../views/HomeWrapper.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Heyou Sverige",
    component: HomeWrapper,
  },
  {
    path: "/vote/godagarning",
    name: "Goda Gärning",
    component: () =>
      import(
        /* webpackChunkName: "godagarning" */ "../views/voting/GodaGarning.vue"
      ),
  },
  {
    path: "/vote/forebild",
    name: "Förebild",
    component: () =>
      import(/* webpackChunkName: "forebild" */ "../views/voting/Forebild.vue"),
  },
  {
    path: "/vote/storahjarta",
    name: "Stora Hjärta",
    component: () =>
      import(/* webpackChunkName: "heart" */ "../views/voting/StoraHjarta.vue"),
  },
  {
    path: "/vote/preferredplatform",
    name: "Platform",
    component: () =>
      import(
        /* webpackChunkName: "heart" */ "../views/voting/PreferredPlatform.vue"
      ),
  },
  {
    path: "/vote/submit",
    name: "Submit",
    component: () =>
      import(/* webpackChunkName: "submit" */ "../components/SubmitVotes.vue"),
  },
  {
    path: "/success",
    name: "Success",
    component: () =>
      import(/* webpackChunkName: "success" */ "../views/Success.vue"),
  },
  {
    path: "/closed",
    name: "Closed",
    component: () =>
      import(/* webpackChunkName: "closed" */ "../views/VotingClosed.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(
        /* webpackChunkName: "redirect" */ "../components/RedirectHome.vue"
      ),
  },
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "Heyou",
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "redirect" */ "../components/RedirectHome.vue"
  //     ),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
