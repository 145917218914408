export default {
  state: {
    godagarning: [
      {
        name: "astridnrdn",
        webp: require("@/assets/images/creators/garning/astrid.webp"),
        jpg: require("@/assets/images/creators/garning/astrid.jpeg"),
        selected: false,
      },
      {
        name: "nattid",
        webp: require("@/assets/images/creators/garning/nathalie.webp"),
        jpg: require("@/assets/images/creators/garning/nathalie.jpg"),
        selected: false,
      },
      {
        name: "ANJO",
        webp: require("@/assets/images/creators/garning/ANJO.webp"),
        jpg: require("@/assets/images/creators/garning/ANJO.jpg"),
        selected: false,
      },
    ],
    forebild: [
      {
        name: "Antonija Mandir",
        webp: require("@/assets/images/creators/forebild/antonija.webp"),
        jpg: require("@/assets/images/creators/forebild/antonija.jpg"),
        selected: false,
      },
      {
        name: "Frida Jernspets",
        webp: require("@/assets/images/creators/forebild/frida.webp"),
        jpg: require("@/assets/images/creators/forebild/frida.jpeg"),
        selected: false,
      },
      {
        name: "Tone Sekelius",
        webp: require("@/assets/images/creators/forebild/tone.webp"),
        jpg: require("@/assets/images/creators/forebild/tone.jpg"),
        selected: false,
      },
    ],
    storahjarta: [
      {
        name: "Astrid Nordin",
        webp: require("@/assets/images/creators/hjarta/astrid.webp"),
        jpg: require("@/assets/images/creators/hjarta/astrid.jpg"),
        selected: false,
      },
      {
        name: "Felicia Bergström",
        webp: require("@/assets/images/creators/hjarta/felicia.webp"),
        jpg: require("@/assets/images/creators/hjarta/felicia.jpg"),
        selected: false,
      },
      {
        name: "Sebastian Tadros",
        webp: require("@/assets/images/creators/hjarta/sebastian.webp"),
        jpg: require("@/assets/images/creators/hjarta/sebastian.jpg"),
        selected: false,
      },
    ],
    platform: [
      {
        name: "Instagram",
        webp: require("@/assets/images/platforms/Instagram.webp"),
        jpg: require("@/assets/images/platforms/Instagram.png"),
        selected: false,
      },
      {
        name: "TikTok",
        webp: require("@/assets/images/platforms/TikTok.webp"),
        jpg: require("@/assets/images/platforms/TikTok.png"),
        selected: false,
      },
      {
        name: "YouTube",
        webp: require("@/assets/images/platforms/YouTube.webp"),
        jpg: require("@/assets/images/platforms/YouTube.png"),
        selected: false,
      },
    ],
  },
  mutations: {
    setSelection(state: any, input: any): void {
      state[input.category][input.index].selected = input.value;
    },
  },
  actions: {
    selectNominee(context: any, payload: any): void {
      for (let i = 0; i < context.state[payload.category].length; i++) {
        if (i != payload.nomineeIndex) {
          context.commit("setSelection", {
            category: payload.category,
            index: i,
            value: false,
          });
        } else {
          context.commit("setSelection", {
            category: payload.category,
            index: i,
            value: true,
          });
          context.commit("setRequestData", {
            field: payload.category,
            value: context.state[payload.category][i].name,
          });
        }
      }
    },
  },
  getters: {
    getGodagarningCreators(state: any) {
      return state.godagarning;
    },
    getForebildCreators(state: any) {
      return state.forebild;
    },
    getStoraHjartaCreators(state: any) {
      return state.storahjarta;
    },
    getPreferredPlatform(state: any) {
      return state.platform;
    },
    isNomineeSelected:
      (state: any) =>
      (category: string, nomineeIndex: number): boolean => {
        return state[category][nomineeIndex].selected ? true : false;
      },
  },
};
