import "@/assets/scss/styles.scss";

import { createApp } from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";

import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
  .use(store)
  .use(router)
  // Heyou KEY
  // .use(VueReCaptcha, { siteKey: "6LddCIIdAAAAAPD-kkhhAJLFPNeit-J8QJjuBfmJ" })
  // DEV KEY:
  .use(VueReCaptcha, { siteKey: "6LeJRQYfAAAAAAyamghcxGoeepf_D6fmN16FlefX" })
  .mount("#app");
