import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header_Home = _resolveComponent("Header-Home")!
  const _component_Hero_Closed = _resolveComponent("Hero-Closed")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header_Home),
    _createVNode(_component_Hero_Closed),
    _createVNode(_component_Footer)
  ], 64))
}