<template>
  <!-- Footer -->
  <footer>
    <img
      alt="heyou_1_svart_sep_59,94_transparent_200.gif"
      min-width="112"
      min-height="112"
      src="@/assets/images/heyou_animation_svart_transparent.gif"
    />
  </footer>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Footer",
  data() {
    return {};
  },
  props: {},
  methods: {},
});
</script>

<style lang="scss" scoped>
// footer
footer {
  background-color: rgba(255, 252, 55, 1);
  min-height: 7rem;

  display: flex;
  justify-content: center;

  img {
    max-width: 7rem;

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      max-width: 9rem;
    }
    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
      max-width: 11rem;
    }
  }
}
</style>
