import { createStore } from "vuex";

import creators from "./modules/creators";

export default createStore({
  modules: {
    creators,
  },
  state: {
    isOpen: false,
    requestData: {
      godagarning: "",
      forebild: "",
      storahjarta: "",
      token: "",
      email: "",
      platform: "",
    },
    votingStep: [
      {
        step: "1",
        done: false,
      },
      {
        step: "2",
        done: false,
      },
      {
        step: "3",
        done: false,
      },
      {
        step: "4",
        done: false,
      },
    ],
  },
  mutations: {
    setRequestData(state: any, payload: any) {
      state.requestData[payload.field] = payload.value;
    },
    clearRequestData(state: any) {
      state.requestData = "";
    },
    setVotingOpen(state: any, isOpen) {
      state.isOpen = isOpen;
      // console.log(`setting api data: ${isOpen}`);
    },
    commitVotingStep(state: any, payload) {
      state.votingStep[payload.step - 1].done = payload.done;
    },
    clearVotingSteps(state: any, step) {
      state.votingStep[step - 1].done = false;
    },
  },
  actions: {
    async setOpen(state, isOpen) {
      await state.commit("setVotingOpen", isOpen);
    },
    setVotingStepDone(state, payload) {
      const status = { done: true, step: payload.currentStep };
      state.commit("commitVotingStep", status);
    },
    clearVotingStepStatus(state) {
      state.commit("clearVotingSteps", 1);
      state.commit("clearVotingSteps", 2);
      state.commit("clearVotingSteps", 3);
      state.commit("clearVotingSteps", 4);
    },
  },
  getters: {
    getRequestData(state) {
      return state.requestData;
    },
    getVotingOpen(state) {
      return state.isOpen;
    },
    getAllVotingStepStatuses(state) {
      return state.votingStep;
    },
    getVotingStepStatus:
      (state) =>
      (currentVotingStep: number): boolean => {
        return state.votingStep[currentVotingStep - 2].done;
      },
  },
});
