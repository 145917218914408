<template>
  <HomeClosed v-if="!isOpen & !showWhite"></HomeClosed>
</template>

<script>
import { defineComponent } from "vue";
import axios from "axios";
import HomeClosed from "@/views/VotingClosed.vue";

export default defineComponent({
  name: "HomeWrapper",
  components: {
    HomeClosed,
  },
  data() {
    return {
      isOpen: false,
      showWhite: true,
    };
  },
  props: {},
  methods: {},
  async mounted() {
    let body = document.body;
    body.classList.add("white");

    await axios
      .get("https://prod-1.heyou.link/dev/api/v1/votes/status", {
        headers: { "Access-Control-Allow-Origin": "*" },
        body: {
          referrer: document.referrer,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          // console.log(`got API data: ${response.data.isOpen}`);
          const votingOpen = response.data.isOpen;

          this.isOpen = votingOpen;
          this.$store.dispatch("setOpen", votingOpen);

          if (votingOpen) {
            window.location.href = "/vote/storahjarta";
          } else {
            this.showWhite = false;
          }
        }
      })
      .catch(() => {
        // Silently fail
      });
  },
});
</script>
