<template>
  <!-- Main Content -->
  <main class="hero">
    <div class="main__content">
      <h1>Liveröstning under <u>Heyou Awards 2022</u></h1>
      <h3>
        Här kommer du live under sändningen kunna rösta på dina favorit
        kreatörer i ett par utvalda kategorier.
      </h3>
      <h4>
        Följ oss gärna på Instagram och TikTok för den senaste informationen
      </h4>
      <h4>
        <a href="http://www.instagram.com/heyou.sverige/">
          <i class="fab fa-instagram"></i>@heyou.sverige</a
        >
      </h4>
      <h4>
        <a href="https://www.tiktok.com/@heyousverige">
          <i class="fab fa-tiktok"></i>@heyousverige</a
        >
      </h4>
    </div>
  </main>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeroClosed",
  data() {
    return {};
  },
  props: {},
  methods: {},
});
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 2rem;
}
</style>
